import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout/LayoutIndex.vue";
import store from "../store";
// import store from '@/store'

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () => import("@/views/login/LoginIndex"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/dashboard/dashBoard"),
        meta: { title: "首页" },
      },
      {
        path: "ana",
        name: "ana",
        component: () => import("@/views/dashboard/analysisAnalyze"),
        meta: { title: "数据分析" },
      },
    ],
  },
  {
    path: "",
    component: Layout,
    children: [
      {
        path: "/user_list",
        component: () => import("@/views/user/UserList"),
        name: "user_list",
        meta: {
          title: "用户列表",
        },
      },
    ],
  },
  {
    path: "",
    component: Layout,
    children: [
      {
        path: "/iframe",
        component: () => import("@/views/iframe/iframe.vue"),
        name: "iframe",
        meta: {
          title: "iframe页面",
        },
      },
    ],
  },
  {
    path: "",
    component: Layout,
    children: [
      {
        path: "/order_list",
        component: () => import("@/views/order/OrderList.vue"),
        name: "order_list",
        meta: {
          title: "订单列表",
        },
      },
    ],
  },
  {
    path: "",
    component: Layout,
    children: [
      {
        path: "/chat",
        component: () => import("@/views/chat/ChatPage.vue"),
        name: "chat",
        meta: {
          title: "聊天列表",
        },
      },
    ],
  },
  {
    path: "",
    component: Layout,
    children: [
      {
        path: "/chat_rule",
        component: () => import("@/views/kefu/ChatRule.vue"),
        name: "chat_rule",
        meta: {
          title: "智能客服知识库",
        },
      },
    ],
  },
  {
    path: "",
    component: Layout,
    children: [
      {
        path: "/common_problem",
        component: () => import("@/views/function/commonProblem.vue"),
        name: "commonProblem",
        meta: {
          title: "常见问题",
        },
      },
      {
        path: "/page_pay_way",
        component: () => import("@/views/function/pagePayWay.vue"),
        name: "page_pay_way",
        meta: {
          title: "付款方式管理",
        },
      },
    ],
  },
  {
    path: "",
    component: Layout,
    children: [
      {
        path: "/daily_data",
        component: () => import("@/views/revenue/DailyData.vue"),
        name: "daily_data",
        meta: {
          title: "收支情况",
        },
      },
      // {
      //   path: "/daily_data_relationship",
      //   component: () => import("@/views/revenue/DailyDataRelationship.vue"),
      //   name: "daily_data_relationship",
      //   meta: {
      //     title: "各推广关系收支情况",
      //   },
      // },
      {
        path: "/income_relationship",
        component: () => import("@/views/revenue/IncomeRelationship.vue"),
        name: "income_relationship",
        meta: {
          title: "推广关系管理",
        },
      },
    ],
  },
  {
    path: "/ghz_cs",
    component: () => import("@/views/cs/cs.vue"),
    name: "cs",
    meta: {
      title: "公众号测试",
    },
  },
  {
    path: "",
    component: Layout,
    children: [
      {
        path: "/openai_account",
        component: () => import("@/views/system/AccountList"),
        name: "openai_account",
        meta: {
          title: "openai账号列表",
        },
      },
      {
        path: "/projectAdmin",
        component: () => import("@/views/system/projectAdmin"),
        name: "projectAdmin",
        meta: {
          title: "openai账号列表",
        },
      },
    ],
  },





  {
    path: "/decryption/setting",
    component: Layout,
    children: [
      {
        path: "pay_way",
        component: () => import("@/views_decryption/setting/pagePayWay.vue"),
        name: "views_decryption-setting-pagePayWay",
        meta: {
          title: "支付账号配置",
        },
      },

    ],
  },


  {
    path: "/setting",
    component: Layout,
    children: [
      {
        path: "/cicd_file",
        component: () => import("@/views/system/cicdfile.vue"),
        name: "cicd_file",
        meta: {
          title: "cicd文件管理",
        },
      },
      {
        path: "/adminsetting2",
        component: () => import("@/views/system/adminsetting2.vue"),
        name: "adminsetting2",
        meta: {
          title: "联合管理端共用配置管理",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (store.state.token === "not login" && to.path !== "/login") {
    next({ path: "/login" });
  }
  if (to.params.title) {
    store.commit("setTitle", to.params.title);
  }
  document.title = `${to.meta.title}`;
  next();
});
router.afterEach(() => {
  window.scrollTo(0, 0);
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
